<template>
    <v-card style="border-radius: 10px">
      <v-layout wrap>
        <v-flex xs4 sm4 md3 lg2 class="mt-4 ml-3">
            <v-avatar
                rounded
                size="120"
                class="me-6"
            >
                <v-img :src="get_image" ></v-img>
            </v-avatar>
        </v-flex>

        <v-flex xs7 md8 lg9 >
            <v-layout wrap class="mt-4">
                <v-flex xs12 sm7 md6 class="text-xs-left pt-4">
                    <div style="border-bottom: 1px solid grey; width: 100%; cursor: pointer"  @click="$refs.file.click()">
                        <input @change="fileInput()" type="file" ref="file" name="file" id="file" class="inputfile" accept=".jpg,.png,.jpeg,.pdf" style=""/>
                        <label for="file"><strong><i class="fas fa-upload "> </i> Upload</strong></label>
                        <span class="text-xs-center" style="font-size: 12px; width: 100%">  {{inputFileName}}</span>
                    </div>
                </v-flex>
                <v-flex xs12 sm4 md6 class="text-xs-left" id="reset_btn">
                    <v-btn @click="updateAccountPic()" outline color="primary darken-2">Update Photo</v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
      </v-layout>

      <div class="container">
        <v-layout wrap>
            <v-flex xs12 sm12 md6 lg6 >
                <v-layout row class="grey--text darken-2 mb-2">
                    <v-flex xs2 sm1>
                        <i class="teal--text fas fa-user fa-2x mt-2 "> </i>                            
                    </v-flex>
                    <v-flex xs10 sm10>
                        <v-text-field class="pa-0" 
                            id="fullnames" v-model="fullnames" ref="fullnames"
                            :rules="[v => !!v || 'Your Full Names is required']"
                            label="Full Names" 
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12 sm12 md6 lg6 >
                <v-layout row class="grey--text darken-2 mb-2">
                    <v-flex xs2 sm1>
                        <i class="teal--text fas fa-user-circle fa-2x mt-2"> </i>                            
                    </v-flex>
                    <v-flex xs10 sm10>
                        <v-text-field class="pa-0"
                            id="username" v-model="username" ref="username"
                            :rules="[v => !!v || 'Username is required']"
                            label="Username"
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-layout wrap>
            <v-flex xs12 sm12 md6 lg6 >
                <v-layout row class="grey--text darken-2 mb-2">
                    <v-flex xs2 sm1>
                        <i class="teal--text fas fa-phone fa-2x mt-2"> </i>                            
                    </v-flex>
                    <v-flex xs10 sm10>
                        <input id="phone" required type="tel" ref="phone_number" value="Enter Phone Number" v-model="phone_number" name="phone" class="py-2 my-2 dark--text" style="color: black; width: 100%!important; border-bottom: 1px grey solid" />
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12 sm12 md6 lg6 >
                <v-layout row class="grey--text darken-2 mb-2">
                    <v-flex xs2 sm1>
                        <i class="teal--text fas fa-envelope fa-2x mt-2 "> </i>                            
                    </v-flex>
                    <v-flex xs10 sm10>
                        <v-text-field class="pa-0"
                            id="email" v-model="email" ref="email"
                            :rules="[v => !!v || 'Your email is required']"
                            label="Email address"
                            color="teal"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <div class="text-xs-center">
            <v-btn 
              color="primary" :disabled="isDisabled"
              class="me-3 mt-4"
              @click="updateAccountDetails()"
            >
              Save changes
            </v-btn>
        </div>
      </div>
    </v-card>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            const status = ['Active', 'Inactive', 'Pending', 'Closed']
            return {
                status,
                loading: false,
                isDisabled: false,
                inputFileName: "Allowed JPG or PNG. Max size of 5Mbytes",
                user_pic: [],
                username: "",
                fullnames: "",
                phone_number: "Enter Phone Number",
                phoneInput: [],
                email: "",
                get_image: "",
            }
        },

        mounted(){
            this.initialiseIntel()
            this.getUserInfo()
            this.$store.commit('setCloseURL', "/")
        },

        methods:{
            initialiseIntel(){
                function getIp(callback) {
                    fetch('https://ipinfo.io/json?token=fa5e74d3e65812', { headers: { 'Accept': 'application/json' }})
                    .then((resp) => resp.json())
                    .catch(() => {
                        return {
                            country: 'cm',
                        };
                    })
                    .then((resp) => callback(resp.country));
                }

                const phoneInputField = document.querySelector("#phone");
                this.phoneInput = window.intlTelInput(phoneInputField, {
                    initialCountry: "auto",
                    geoIpLookup: getIp,
                    preferredCountries: ["cm", "us", "uk", "ci", "cg", "cf", "td", "ng", "gq", "ga"],
                    utilsScript:
                    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
                });
            },

            async updateAccountDetails(){
                console.log(this.$store.state.user_info)
                if(this.username.length < 4){
                    this.$store.commit('setSnackBarMessage', "User name is too short, user name needs to be at least 4 characters")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.username.focus()  // this causes a an auto fucos to the element
                }
                else if(this.fullnames.length === ""){
                    this.$store.commit('setSnackBarMessage', "Please input full names")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.fullnames.focus()  // this causes a an auto fucos to the element
                }
                else if(!this.phoneInput.isValidNumber()){
                    this.$store.commit('setSnackBarMessage', "Phone number is invalid. Please input a valid number")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
                }
                else if(this.email.length === ""){
                    this.$store.commit('setSnackBarMessage', "Please input email")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.email.focus()  // this causes a an auto fucos to the element
                }
                else if(this.user_pic.size > 6000000){
                    this.$store.commit('setSnackBarMessage', "Please upload a picture less than 5mb")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.user_pic.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.loading = true
                    this.isDisabled = true
                    console.log(this.user_pic)

                    let formData = new FormData()
                    formData.append('username', this.username)
                    formData.append('fullnames', this.fullnames)
                    formData.append('phone_number', this.phoneInput.getNumber())
                    formData.append('email', this.email)
                    formData.append('user_pic', this.user_pic)

                    await axios
                        .post('/api/v1/home/update_account_details/account/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                            if (response.data[0].category === "success"){
                                this.getUserInfo()
                            }
                            this.isDisabled = false
                        })
                        .catch(error => {
                            if (error.response){
                                for (const property in error.response.data){
                                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
        
                    this.loading = false
                }

            },

            async updateAccountPic(){
                var File= document.getElementById('file')
                console.log(File)
                if(!File.files.item(0)){
                    this.$store.commit('setSnackBarMessage', "Please upload your profile picture")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.file.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.loading = true
                    let formData = new FormData()
                    formData.append('user_pic', this.file)

                    await axios
                        .post('/api/v1/topup/update_account_details/user_pic/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 

                            if (response.data[0].category === "success"){
                                this.getUserInfo()
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                for (const property in error.response.data){
                                    this.$store.commit('setSnackBarMessage', error.response.data[property][0])
                                    this.$store.commit('setSnackBarColor', "red darken-4")
                                    this.$store.commit('activateSnackBar', true)
                                }
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured while logging into your account")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
        
                    this.loading = false
                }

            },

            getUserInfo() {
                if (this.$store.state.isAuthenticated){
                    axios
                        .get('/api/v1/home/get/user/info/')
                        .then(response => {
                            this.$store.commit('setUser', response.data[0]["user"])
                            this.$store.commit('setUserState', response.data[0]["user_info"])
                            this.$store.commit('setUserInfo', response.data[0])
                            this.$store.commit('setUserName', response.data[0]["user"]["username"])

                            this.username = this.$store.state.user_info["user"]["username"]
                            this.fullnames = this.$store.state.user_info["user"]["first_name"]
                            this.phone_number = this.$store.state.user_info["user_profile"]["phone_number"]
                            this.email = this.$store.state.user_info["user"]["email"]
                            this.get_image = this.$store.state.user_info["user_profile"]["get_image"]
                        })
                        .catch(error => {
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        })
                }
            },

            fileInput(){
                var File= document.getElementById('file')
                if(File.files.item(0)){
                    var FileName = File.files.item(0).name
                    var FileSize = File.files.item(0).size
                    var FileType = File.files.item(0).type
                    console.log(FileType)
                    FileName = FileName.replace(/.*[\/\\]/, '_')  // https://stackoverflow.com/questions/3437786/get-the-size-of-the-screen-current-web-page-and-browser-window
                    var win = window,
                        doc = document,
                        docElem = doc.documentElement,
                        body = doc.getElementsByTagName('body')[0],
                        screenWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
                        screenHeight = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
                    
                    if (FileSize >= 5242880){
                        this.$store.commit('setSnackBarMessage', "Your profile picture exceeded the size limit of 5Mb. Upload a file less than 5 megabytes")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Select Profile Picture"
                    }
                    else if(!FileType.includes("jpeg") && !FileType.includes("jpg") && !FileType.includes("png")){
                        this.$store.commit('setSnackBarMessage', "Your profile picture must be an image (.png, .jpeg, .jpg)")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.file.focus()  // this causes a an auto fucos to the element
                        this.inputFileName = "Select Profile Picture"
                    }
                    else{
                        if (FileName.length > 20){
                            if (screenWidth <= 760){
                                FileName = FileName.substring(0, 10) + '...' + FileName.substring(FileName.length-9, FileName.length) 
                            }
                        }
                        this.inputFileName = FileName
                        this.file = this.$refs.file.files[0]
                    }
                }
                else{
                    this.inputFileName = "Select Profile Picture"
                }
            },

        },
    }
</script>

<style scoped>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
        color: white;
        background-color: rgb(0, 26, 255);
        display: inline-block;
    }
    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgb(55, 67, 230);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
        pointer-events: none;
    }

    @media (max-width: 420px) {
        #reset_btn {
            margin-top: 0!important;
        }
    }

    @media (min-width: 420px) {
        #reset_btn {
            margin-top: 25px!important;
        }
    }
</style>
